<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Настройки Workflow"
    >
      <v-tabs>
        <v-tab v-for="item in items" :key="item.guid">{{ item.name }}</v-tab>
        <v-tab-item v-for="item in items" :key="item.guid">
          <v-row>
            <v-col
              v-for="data in item.settings_data"
              :key="data.guid"
              cols="12" lg="6"
            >
              <v-switch
                v-model="data.val"
                :label="data.name"
                @change="change(data, item.group)"
              ></v-switch>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: 'SettingsWorkflow',
  data: () => ({
    items: []
  }),
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$store.dispatch('settings/GET_SETTINGS', 'workflow').then(response => {
        this.items = response
      })
    },
    change (item, group) {
      const payload = {
        guid: item.guid,
        value: item.val,
        group: group
      }
      this.$store.dispatch('settings/POST_SETTING', payload)
    }
  }
}
</script>

<style scoped>

</style>
